@font-face {
  font-family: 'lato-regular';
  src: url("./assets/fonts/Lato-Regular.ttf");
}

@font-face {
  font-family: 'lato-bold';
  src: url("./assets/fonts/Lato-Bold.ttf");
}

@font-face {
  font-family: 'lato-heavy';
  src: url("./assets/fonts/Lato-Heavy.ttf");
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

h1, h2, h3, h4 {
  font-family: "lato-heavy";
}

h1 {
  font-size: 30px;
  margin-bottom: 16px;
}

h2 {
  font-size: 20px;
  margin-bottom: 8px;
}

h3 {
  font-size: 16px;
  margin-bottom: 8px;
}

h4 {
  font-size: 14px;
  margin-bottom: 8px;
}

div, span, p {
  font-family: "lato-regular";
  font-size: 12px;
  margin-bottom: 8px;
}

* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-weight: inherit;
  color: inherit;
  text-decoration: none;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4 {
  margin: 0;
}

div, span, p {
  margin-bottom: 0;
}

.app-root {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  overflow-y: scroll;
  padding: 16px;
}

.top-bar {
  width: 100%;
  margin: 0px;
}

.top-bar::after {
  content: "";
  clear: both;
  display: table;
}

.search-bar {
  float: left;
  width: calc(100% - 100px);
}

.search-bar input {
  width: 100%;
}

.search-results {
  position: absolute;
  top: 40px;
  background: #fff;
  border: 1px solid black;
}

.button {
  border-radius: 1px;
  background-color: white;
  border: 1px solid black;
  margin-bottom: 20px;
  padding: 0 10px;
  width: auto;
  height: 35px;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.login-button {
  text-transform: uppercase;
  letter-spacing: 1px;
  float: right;
}

.white-space-wrapping {
  white-space: pre-wrap;
}

.rotation-position-1 {
  transform: rotate(0deg);
}

.rotation-position-2 {
  transform: rotate(90deg);
}

.rotation-position-3 {
  transform: rotate(180deg);
}

.rotation-position-4 {
  transform: rotate(270deg);
}

.clockwise {
  float: left;
}

.counterClockwise {
  float: right;
}

.blobPreviewSlider {
  width: 1000px;
  max-width: 100%;
}
